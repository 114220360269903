<template>
  <div>
    <HelloWorld class="animated-component"/>
    <ImageSection class="animated-component"/>
    <PageFooter class="animated-component"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import ImageSection from '@/components/Images.vue'
import PageFooter from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    ImageSection,
    PageFooter
  }
}
</script>

<style scoped>
.animated-component{
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

@keyframes enterAnimation {
  from{
    opacity: 0;
    transform: translateY(50px);
  }

  to{
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-component{
  animation-name: enterAnimation;
}
</style>
