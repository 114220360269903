<template>
  <nav>
    <router-link to="/WallPanel"></router-link> 
    <router-link to="/GardenView"></router-link> 
    <router-link to="/JewelleryView"></router-link>
    <router-link to="/LawnView"></router-link>
  </nav>
  <router-view/>
</template>

<style>
</style>
