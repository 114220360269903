// store/index.js
import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      imageUrl: null,
    };
  },
  mutations: {
    setImageUrl(state, url) {
      state.imageUrl = url;
    },
  },
  actions: {
    setImageUrl(context, url) {
      context.commit('setImageUrl', url);
    },
  },
  getters: {
    imageUrl: (state) => state.imageUrl,
  },
});

export default store;
